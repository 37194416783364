<template>
  <h2>About Net Art Marathon</h2>

  <div class="row" style="margin-top: 50px">
    <div class="col col-12 col-md-6 col-lg-6">
      In the nineties and noughties, net art, i.e. art with Internet technologies, was in its heyday. Artists like <a href="https://JODI.org" target="_blank">JODI</a>, <a href="https://en.wikipedia.org/wiki/Alexei_Shulgin" target="_blank">Alexei Shulgin</a>, <a href="https://en.wikipedia.org/wiki/Vuk_%C4%86osi%C4%87" target="_blank">Vuk Cosic</a>, <a href="https://de.wikipedia.org/wiki/Olia_Lialina" target="_blank">Olia Lialina</a> and others have demonstrated the creative potential of the web browser.
      <p />
      In recent years however, more modern digital technologies have robbed net art of its popularity, with web technologies evolving tremendously - especially after 2010. Concepts like responsive design, CSS3 animations and transitions, rich multimedia elements, and even the ability to create three-dimensional worlds using CSS or WebGL have made possible entirely new experiences. Web browsers have continously evolved into highly complex software platforms that not only display information, but provide a stable foundation for modern software development.
    </div>
  </div>

  <div class="row" style="margin-top: 50px">
    <div class="col col-12 col-md-6 col-lg-6">&nbsp;</div>

    <div class="col col-12 col-md-6 col-lg-6 bold">
      The goal of this project is to explore the creative-artistic potential of modern web technologies using an exploratory methodology. Based on a few defined conceptual parameters, this should result in a consistent and visually cohesive collection of ideas that can later serve as a foundation for larger projects.
    </div>
  </div>
</template>


<style lang="scss">
</style>
